import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3cbd6c1a")
const _hoisted_1 = { class: "fluid-container container-slider-news" }
const _hoisted_2 = { class: "container text-start" }
const _hoisted_3 = { class: "container-news-grid" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "container container-news" }
const _hoisted_6 = { class: "container container-blog-mini" }
const _hoisted_7 = { class: "container-tips" }
const _hoisted_8 = { class: "container" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SliderNewsCards = _resolveComponent("SliderNewsCards")!
  const _component_SectionHomeGridNews = _resolveComponent("SectionHomeGridNews")!
  const _component_SectionHomeNews = _resolveComponent("SectionHomeNews")!
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_SectionBlogMini = _resolveComponent("SectionBlogMini")!
  const _component_SectionTips = _resolveComponent("SectionTips")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_SliderNewsCards)
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_SectionHomeGridNews)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_SectionHomeNews)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_SectionHeading, {
              class: "section-heading",
              title: "Gambling Tips & Tricks",
              subtitle: "Just Flip a Coin",
              description: "At Modeltheme, we show only the best websites and portfolios builtcompletely with passion, simplicity & creativity!"
            }),
            _createVNode(_component_SectionBlogMini)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_SectionHeading, {
                class: "section-heading",
                title: "Reviews For Bookmakers",
                subtitle: "Just Flip a Coin",
                description: "At Modeltheme, we show only the best websites and portfolios builtcompletely with passion, simplicity & creativity!"
              }),
              _createVNode(_component_SectionTips)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}