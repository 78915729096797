
import { defineComponent } from "vue";
import SectionHomeGridNews from "../components/SectionHomeGridNews/SectionHomeGridNews.vue";
import SectionHomeNews from "../components/SectionHomeNews/SectionHomeNews.vue";
import SectionTips from "@/components/SectionTips/SectionTips.vue";
import SectionHeading from "@/components/SectionHeading/SectionHeading.vue";
import SectionBlogMini from "@/components/SectionBlogMini/SectionBlogMini.vue";
import SliderNewsCards from "@/components/SliderNewsCards/SliderNewsCards.vue";
export default defineComponent({
  name: "Home",
  components: {
    SectionHomeGridNews,
    SectionHomeNews,
    SectionTips,
    SectionHeading,
    SectionBlogMini,
    SliderNewsCards,
  },
  setup() {
    document.title = "Home Blogging - Coinflip";
  },
});
