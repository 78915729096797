import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-60bd92f2")
const _hoisted_1 = { class: "container" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardBlogPost = _resolveComponent("CardBlogPost")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, { gutter: [32, 32] }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          lg: 8,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardBlogPost, {
              class: "mini-card",
              type: "mini",
              thumbnail: { url: 'images/misc/feature_casino4.jpg' },
              href: "'https://modeltheme.com/go/coinflip/'",
              title: "Four ways to cheer yourself up on the Next Blue Monday!",
              details: {
            href: 'https://modeltheme.com/go/coinflip/',
            category: 'Coinflip',
            date: _ctx.$formatDate(new Date()),
          },
              author: {
            href: 'https://modeltheme.com/go/coinflip/',
            name: 'Coinflip',
          }
            }, null, 8, ["thumbnail", "details", "author"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          lg: 8,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardBlogPost, {
              type: "mini",
              class: "mini-card",
              thumbnail: { url: 'images/misc/feature_casino7.jpg' },
              title: "How to Properly Use Casino Fibonacci System for now!",
              details: {
            href: 'https://modeltheme.com/go/coinflip/',
            category: 'Coinflip',
            date: _ctx.$formatDate(new Date()),
          },
              author: {
            href: 'https://modeltheme.com/go/coinflip/',
            name: 'Coinflip',
          },
              href: "'https://modeltheme.com/go/coinflip/'"
            }, null, 8, ["thumbnail", "details", "author"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          lg: 8,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardBlogPost, {
              class: "mini-card",
              type: "mini",
              thumbnail: { url: 'images/misc/article-blog-10.jpg' },
              title: "How to Properly Use Casino Fibonacci System for now",
              details: {
            href: 'https://modeltheme.com/go/coinflip/',
            category: 'Coinflip',
            date: _ctx.$formatDate(new Date()),
          },
              author: {
            href: 'https://modeltheme.com/go/coinflip/',
            name: 'Coinflip',
          },
              href: "'https://modeltheme.com/go/coinflip/'"
            }, null, 8, ["thumbnail", "details", "author"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          lg: 8,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardBlogPost, {
              class: "mini-card",
              type: "mini",
              thumbnail: { url: 'images/misc/article-blog-8.jpg' },
              href: "'https://modeltheme.com/go/coinflip/'",
              title: "Slot Machine that has no paylines",
              details: {
            href: 'https://modeltheme.com/go/coinflip/',
            category: 'Coinflip',
            date: _ctx.$formatDate(new Date()),
          },
              author: {
            href: 'https://modeltheme.com/go/coinflip/',
            name: 'Coinflip',
          }
            }, null, 8, ["thumbnail", "details", "author"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          lg: 8,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardBlogPost, {
              type: "mini",
              class: "mini-card",
              thumbnail: { url: 'images/misc/feature_casino7.jpg' },
              title: "Which are the Most Popular Roulette Systems?",
              details: {
            href: 'https://modeltheme.com/go/coinflip/',
            category: 'Coinflip',
            date: _ctx.$formatDate(new Date()),
          },
              author: {
            href: 'https://modeltheme.com/go/coinflip/',
            name: 'Coinflip',
          },
              href: "'https://modeltheme.com/go/coinflip/'"
            }, null, 8, ["thumbnail", "details", "author"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          lg: 8,
          xs: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardBlogPost, {
              type: "mini",
              class: "mini-card",
              href: "'https://modeltheme.com/go/coinflip/'",
              thumbnail: { url: 'images/demosimages/article-blog-1.jpeg' },
              title: "Classic Books Based Online Slot and the Roulettes",
              details: {
            href: 'https://modeltheme.com/go/coinflip/',
            category: 'Coinflip',
            date: _ctx.$formatDate(new Date()),
          },
              author: {
            href: 'https://modeltheme.com/go/coinflip/',
            name: 'Coinflip',
          }
            }, null, 8, ["thumbnail", "details", "author"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}