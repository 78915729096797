import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6ef95366")
const _hoisted_1 = { class: "news-slider" }
const _hoisted_2 = { class: "first-column me-2" }
const _hoisted_3 = { class: "second-column d-flex flex-column" }
const _hoisted_4 = { class: "element-one mb-2" }
const _hoisted_5 = { class: "d-flex element-two" }
const _hoisted_6 = { class: "me-2 w-50" }
const _hoisted_7 = { class: "w-50" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardBlogPost = _resolveComponent("CardBlogPost")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, { class: "slider-row" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { lg: 12 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_CardBlogPost, {
                class: "mini-card",
                thumbnail: { url: 'images/misc/feature_casino7.jpg' },
                title: "Four ways to cheer yourself up on the Next Blue Monday!",
                details: {
              href: 'https://modeltheme.com/go/coinflip/',
              category: 'Betting news',
              date: _ctx.$formatDate(new Date()),
            },
                author: {
              href: 'https://modeltheme.com/go/coinflip/',
              name: 'Jason McElwaine',
            },
                href: "https://modeltheme.com/go/coinflip/"
              }, null, 8, ["thumbnail", "details", "author"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { lg: 12 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_CardBlogPost, {
                  class: "mini-card sc-card-one",
                  thumbnail: { url: 'images/misc/article-blog-10.jpg' },
                  title: "This Girl Discovered What Everyone Wants",
                  details: {
                href: 'https://modeltheme.com/go/coinflip/',
                category: 'Bookmakers',
                date: _ctx.$formatDate(new Date()),
              },
                  author: {
                href: 'https://modeltheme.com/go/coinflip/',
                name: 'Jason McElwaine',
              },
                  href: "https://modeltheme.com/go/coinflip/"
                }, null, 8, ["thumbnail", "details", "author"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_CardBlogPost, {
                    class: "mini-card sc-card-two",
                    thumbnail: { url: 'images/misc/feature_casino4.jpg' },
                    title: "Overiew on tho Casino Games",
                    details: {
                  href: 'https://modeltheme.com/go/coinflip/',
                  category: 'Gambling',
                  date: _ctx.$formatDate(new Date()),
                },
                    author: {
                  href: 'https://modeltheme.com/go/coinflip/',
                  name: 'Jason McElwaine',
                },
                    href: "https://modeltheme.com/go/coinflip/"
                  }, null, 8, ["thumbnail", "details", "author"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_CardBlogPost, {
                    class: "mini-card sc-card-three",
                    thumbnail: { url: 'images/misc/article-blog-8.jpg' },
                    title: "What you didn't know about This Slot",
                    details: {
                  href: 'https://modeltheme.com/go/coinflip/',
                  category: 'ForPlayers',
                  date: _ctx.$formatDate(new Date()),
                },
                    author: {
                  href: 'https://modeltheme.com/go/coinflip/',
                  name: 'Jason McElwaine',
                },
                    href: "https://modeltheme.com/go/coinflip/"
                  }, null, 8, ["thumbnail", "details", "author"])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}