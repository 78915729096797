
import { defineComponent } from "vue";
import CardNews from "@/components/CardNews/CardNews.vue";

export default defineComponent({
  name: "Home",
  components: {
    CardNews,
  },
});
