import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionSliderNews = _resolveComponent("SectionSliderNews")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    "slides-per-view": 1,
    centeredSlides: true,
    spaceBetween: 30,
    grabCursor: true,
    pagination: {
      clickable: true,
    }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_SectionSliderNews, { class: "text-start" })
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_SectionSliderNews, { class: "text-start" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}