
import { defineComponent } from "vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper";
// install Swiper modules
SwiperCore.use([Pagination]);
import "swiper/swiper-bundle.min.css";
import SectionSliderNews from "../SectionSliderNews/SectionSliderNews.vue";
// Import Swiper styles
import "swiper/swiper.min.css";

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    SectionSliderNews,
  },
});
