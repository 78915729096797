import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardNews = _resolveComponent("CardNews")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, { gutter: [32, 15] }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { lg: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_CardNews, {
              thumbnail: { url: 'images/demosimages/card-blog-1.jpeg' },
              title: "Four ways to cheer yourself up on the Next Blue Monday!",
              description: "Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum complectitur et, eam ut veri",
              author: {
            href: 'https://modeltheme.com/go/coinflip/',
            name: 'Jason McElwaine',
          },
              href: "https://modeltheme.com/go/coinflip/"
            }, null, 8, ["thumbnail", "author"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { lg: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_CardNews, {
              thumbnail: { url: 'images/misc/article-blog-8.jpg' },
              title: "Four ways to cheer yourself up on the Next Blue Monday!",
              description: "Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum complectitur et, eam ut veri",
              author: {
            href: 'https://modeltheme.com/go/coinflip/',
            name: 'Jason McElwaine',
          },
              href: "https://modeltheme.com/go/coinflip/"
            }, null, 8, ["thumbnail", "author"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { lg: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_CardNews, {
              thumbnail: { url: 'images/misc/article-blog-10.jpg' },
              title: "Four ways to cheer yourself up on the Next Blue Monday!",
              description: "Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum complectitur et, eam ut veri",
              author: {
            href: 'https://modeltheme.com/go/coinflip/',
            name: 'Jason McElwaine',
          },
              href: "https://modeltheme.com/go/coinflip/"
            }, null, 8, ["thumbnail", "author"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}