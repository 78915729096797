
import { defineComponent } from "vue";
import CardBlogPost from "../../components/CardBlogPost/CardBlogPost.vue";

export default defineComponent({
  name: "Home",
  components: {
    CardBlogPost,
  },
});
